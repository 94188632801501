import React from "react";
import { graphql } from "gatsby";

import { renderArchiveBlocks } from "../js/pageBlockRender";

import Layout from "../components/global/Layout";
import SEO from "../components/global/SEO";

import "../components/sass/blocks.scss";

const WhatsNewTemplate = ({ data, ...rest }) => (
  <Layout>
    {!!data.page && <SEO yoast={data.page.yoast} />}
    {renderArchiveBlocks(
      !!data.page && data.page.acf !== null ? data.page.acf.page_blocks : [],
      data.allThoughtPieces.edges
    )}
  </Layout>
);

export default WhatsNewTemplate;

export const whatsNewQuery = graphql`
  query($pageId: String!) {
    allThoughtPieces: allWordpressThoughtPieceEndpointThoughtPieces {
      edges {
        node {
          post_excerpt
          thought_categories {
            name
          }
          post_date
          post_title
          acf {
            gated
            legacy
            thought_piece_blocks {
              block_type {
                acf_fc_layout
                authors {
                  a_company
                  a_name
                  a_title
                  a_headshot {
                    localFile {
                      childImageSharp {
                        fluid(quality: 100, maxWidth: 1000) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    page: wordpressPage(id: { eq: $pageId }) {
      title
      id
      yoast {
        focuskw
        title
        metadesc
        opengraph_title
        opengraph_description
        opengraph_updated_time
        twitter_title
        twitter_description
        linkdex
        metakeywords
        meta_robots_noindex
        meta_robots_nofollow
        meta_robots_adv
        canonical
        redirect
        opengraph_url
        opengraph_site_name
        opengraph_type
      }
      acf {
        page_blocks {
          block_type {
            acf_fc_layout

            pch_small_title
            pch_context
            pch_text
            pch_title

            dl_style

            q_intro
            q_quote
            q_quotee

            rcs_intro_title
            rcs_related_cases {
              case_study {
                post_title
              }
              image {
                alt_text
                localFile {
                  childImageSharp {
                    fluid(quality: 100, maxWidth: 4000) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

/* tcmts_bg_color
tcmts_text
tcmts_media {
  localFile {
    childImageSharp {
      fluid(quality: 100, maxWidth: 4000) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
}, */
